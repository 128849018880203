import { ProductTimedValueParams } from 'store/products';
import { selectById } from './products.selectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './products.consts';
import { ProductParams } from './products.types';
import axios from 'axios';
import {
  normalizeDateForApi,
  normalizeDateFromApi,
} from 'helpers/datetimeHelpers';

function normalizeApiReponse(data: ProductParams) {
  if (!data.timedSegments) {
    return data;
  }
  const segments = data.timedSegments.map(segment => ({
    ...segment,
    startDate: normalizeDateFromApi(segment.startDate),
    endDate: normalizeDateFromApi(segment.endDate),
  }));
  return { ...data, timedSegments: segments };
}

function normalizeApiRequest(data: ProductParams) {
  if (!data.timedSegments) {
    return data;
  }
  const segments = data.timedSegments.map(segment => ({
    ...segment,
    startDate: normalizeDateForApi(segment.startDate),
    endDate: normalizeDateForApi(segment.endDate),
  }));
  return { ...data, timedSegments: segments };
}

const fetchByIdHandler = async ({ productId }: { productId: number }) => {
  const product = await adserver({
    url: `/product/${productId}`,
    headers: {},
  });

  return normalizeApiReponse(product.data);
};

export const fetchAllProducts = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async ({ query }: { query: string }) => {
    const response = await adserver({
      url: '/products' + (query ? `?${query}` : ''),
      headers: {},
    });
    return response.data.map((product: ProductParams) =>
      normalizeApiReponse(product),
    );
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ productId }: { productId: number }) => {
    const response = await adserver({
      url: `/product/${productId}`,
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (values: ProductParams) => {
    const response = await adserver({
      url: '/product/',
      method: 'POST',
      data: normalizeApiRequest(values),
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: ProductParams) => {
    const { id: productId } = data;

    const response = await adserver({
      url: `/product/${productId}`,
      method: 'PATCH',
      data: normalizeApiRequest(data),
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(async ({ productId }: { productId: number }) => {
    await adserver({
      url: `/product/${productId}`,
      method: 'DELETE',
      headers: {},
    });
    return productId;
  }),
);

export const setCurrentProduct = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (productId: number) => {
    if (!productId) return;
    const product = await fetchByIdHandler({ productId });
    return product;
  }),
);

export const removeAllProducts = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});

export const toggleStatus = createAsyncThunk(
  REDUCER_KEY + '/toggleStatus',
  withErrorHandling(async (productId: number, thunkAPI: any) => {
    const product = selectById(thunkAPI.getState(), productId);
    if (!product) return;
    const isActive = !product?.isActive;
    const reqData = {
      isActive,
    };
    const response = await adserver({
      url: `/product/${productId}`,
      method: 'PATCH',
      data: reqData,
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const clone = createAsyncThunk(
  REDUCER_KEY + '/clone',
  withErrorHandling(async (productId: number, thunkApi: any) => {
    const product: ProductParams = await fetchByIdHandler({ productId });
    if (!product) return;

    const timeSegments = product.timedSegments.map(
      (segment: ProductTimedValueParams) => {
        const { id, ...rest } = segment;
        return rest;
      },
    );

    const clonedProductObj = {
      ...product,
      generalValue: {
        ...product.generalValue,
        name: `${
          product.generalValue.name
        } (sklonowane o ${new Date().toLocaleString()})`,
      },
      timedSegments: timeSegments,
    };
    return await thunkApi.dispatch(create(clonedProductObj));
  }),
);

export const fileData = createAsyncThunk(
  REDUCER_KEY + '/fileData',
  withErrorHandling(async (fileId: number) => {
    const result = await adserver({
      url: `/product/specification/${fileId}`,
      headers: {},
    });
    return result.data;
  }),
);

export const getFile = createAsyncThunk(
  REDUCER_KEY + '/geFile',
  withErrorHandling(async ({ fileId }: { fileId: number }) => {
    const result = await adserver({
      url: `/product/specification/${fileId}`,
      headers: {},
    });
    if (!result) return;
    const { data } = await axios.get(result?.data.uploadedFile, {
      responseType: 'blob',
    });
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.data.name);
    document.body.appendChild(link);
    link.click();
  }),
);

export const fetchNumberOfNewProducts = createAsyncThunk(
  REDUCER_KEY + '/fetchNumberOfNewProducts',
  withErrorHandling(async ({ query }: { query: string }) => {
    const response = await adserver({
      url: `/products?${query}`,
      headers: {},
    });
    return response.data;
  }),
);

export const fetchProductMonthlyExtraFee = createAsyncThunk(
  'monthlyextrafees/products/',
  withErrorHandling(async ({ productId }: { productId: number }) => {
    const response = await adserver({
      url: `${productId}`,
      headers: {},
    });
    return response.data;
  }),
);
