export const REDUCER_KEY = 'proposal';

export const DRAFT = 'BUYER_DRAFT';
export const BUYER_REVIEW_REQUESTED = 'BUYER_REVIEW_REQUESTED';
export const PUBLISHER_REVIEW_REQUESTED = 'PUBLISHER_REVIEW_REQUESTED';
export const BUYER_ACCEPTANCE_REQUESTED = 'BUYER_ACCEPTANCE_REQUESTED';
export const NEGOTIATIONS_FINISHED = 'NEGOTIATIONS_FINISHED';
export const TERMINATED = 'TERMINATED';
export const SUSPENDED = 'SUSPENDED';

export const NEGOTIATIONS = [
  DRAFT,
  BUYER_REVIEW_REQUESTED,
  PUBLISHER_REVIEW_REQUESTED,
  BUYER_ACCEPTANCE_REQUESTED,
  NEGOTIATIONS_FINISHED,
  TERMINATED,
];
